.echart-card {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2) !important;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 0px rgb(27 49 66 / 13%);
    border-radius: 10px;
    padding: 1rem;
}

.card-dashboard {
    min-height: 410px;
}

.echart-card>.ant-card-head {
    border: none;
    font-family: Poppins;
    font-size: 0.938rem;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.2em;
    text-align: center;
    color: #A3A3A3;

}

.card-dashboard-md {
    height: 492px;
}

.hm-echart-md {
    min-height: 400px;
}

.hm-echarts-8 {
    height: 300px;
}

.contenedor {
    position: relative;
    width: 220px;
	height: 330px;
    overflow: visible;
}


.card-hacienda {
    width: 100%;
    height: 90%;
    position: absolute;
    top: 0;
    left: 0;
}

.banner {
    width: 100%;
    height: 90%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.banner-vendido{
    transform: rotate(-45deg);
    background: red;
    width: 300px;
    color: #fff !important;
    text-align: center;
    font-size: 20px;
}

@media (max-width:1024px) {
    .echart-card>.ant-card-head {
        font-size: 0.7rem;
        letter-spacing: 0.1rem;
    }


}

@media (max-width:992px) {
    .echart-card>.ant-card-head {
        font-size: 0.9rem;
        letter-spacing: 0.2rem;
    }

}