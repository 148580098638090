.video{
    width: 100%;
    position: relative;

    &-cover{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        background-color: rgba(0, 0, 0, 0.5);

        display: flex;
        justify-content: center;
        align-items: center;

        z-index: 50;
        
        cursor: pointer;
    }

    &-button{
        width: 250px;
        height: 250px;
    }

    &-player{
        width: 100%;
    }
}

.wrap-modal{
    background-color: rgba(0, 0, 0, 0.9) !important;
}

.modal-video{
    .ant-modal-body{
        padding: 6px;
    }
}

.video-mobile{
    display: flex;
    width: 100%;
    height: 300px;
    justify-content: center;
    align-items: center;
    background-color: gray;
    background-blend-mode: multiply;
    background-size: cover;
    background-repeat: no-repeat;
}

@media screen and (max-width: 1200px) {
    .video{
        &-button{
            width: 200px;
            height: 200px;
        }
    }
}

@media screen and (max-width: 768px) {
    .video{
        &-button{
            width: 175px;
            height: 175px;
        }
    }
}

@media screen and (max-width: 576px) {
    .video{
        &-button{
            width: 120px;
            height: 120px;
        }
    }
}