.terminos.landing .main{
    height: 40vh;
}

.terminos .container{
    padding: 2rem 1rem;
}

.terminos.landing .text-parrafo{
	color: #11142D;
	text-align: left;
}