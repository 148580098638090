.card-hacienda-compra {
    .ant-card-body {
        padding: 0;


        .hacienda-content {
            padding: 24px;
        }

        .menu-options{
            transition: 0.2s all ease-in;
            opacity: 0.7;
            // filter: blur(1px);

        &:hover{
                opacity: 1;
                // filter: blur(0);
            }
        }
    }
}