.card-zeus {
    background: rgba(255, 255, 255, 0.5) !important;
    border: 1px solid rgba(255, 255, 255, 1)
}

.card-zeus .ant-card-head-title{
    font-size: 25px;
    font-weight: 700;
    color: #65CCCC;
}

.zeus-title{
    font-size: 25px !important;
    font-weight: 500 !important;
    color: #65CCCC !important;
}

