
.input-search-product {
	background: rgba(22, 177, 194, 0.5) !important;
	border-radius: 8px;
	border: none !important;
}
.input-search-product * {
	color: white;
	background: transparent !important;
}
.input-search-product button{
	border: none;
}
.input-search-product input::placeholder {
  color: white;
  
}

.tabs-categorias .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
	color: var(--cyan)
}

.tabs-categorias .ant-tabs-ink-bar{
	background: var(--cyan);
}

.select-cena{
	border:  solid 2px var(--cyan-dark);
	border-radius: 8px;
	background: transparent;
}
.select-cena .ant-select-selector{
	background: transparent !important;
}

.select-cena .ant-select-selection-item,
.select-cena .ant-select-arrow svg{
	color: var(--cyan-dark) !important;
	fill:  var(--cyan-dark);
}

.products-container{
	margin-top: 1rem;
	height: 700px;
	max-height: 700px;
	overflow-x: auto;
	padding-top: 2rem;
}

.card-venta .divder-top{
	border-bottom: solid 2px #E1EAEE;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	color: var(--cyan);
}

.card-venta .ant-card-body{
	padding: 1rem 1rem;
	min-height: calc(100vh - 80px - 20px);
}

.card-venta .products-container{
	max-height: 600px;
	overflow:  auto;
	overflow-x: hidden;
	border-bottom: solid 2px #E1EAEE;
	margin-bottom: 2rem;

}

.card-venta .btn-efectivo{
	width: 158px;
	height: 52px;
	background: #07F44A;
	border-radius: 8px;
	border: none;
	color: white;

}

.card-venta .btn-terminal{
	width: 158px;
	height: 52px;
	background: #BE03FF;
	border-radius: 8px;
	border: none;
	color: white;

}

.btn-payment{
	background: #07F44A;
}
