/*
*
* CSS para el Landing Publico
*
*/
$color_1: white;
$color_2: #11142D;
$color_3: #92929D;
$color_4: #2AD590;
$color_5: #16B1C2;
$background-color_1: #2AD590;

.section {
	max-width: 1400px;
	display: block;
	margin: auto;
}

.mt-50 {
	padding-top: 50px;

}

.mb-50 {
	padding-bottom: 50px;
}

.mt-150 {
	padding-top: 9.375rem;
}

.mt-100 {
	padding-top: 100px;
}

.clearfix::after {
	display: block;
	content: "";
	clear: both;
}

.landing {
	.layout {
		&-img-text {
			.layout {
				&-text .ant-typography {
					font-size: 18px;
				}
			}

			.layout-img {
				height: 800px;
			}
		}
	}

}

.landing {
	.main {
		max-height: 100vh;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		height: 88vh;
		position: absolute;
		width: 100%;
		top: 7em;
		right: 0;
	}

	.carousel-bg {
		max-height: 80vh;
		object-fit: contain;

		img {
			width: 100%;
			height: 80vh;
			object-fit: cover;
		}

		position: fixed;
		top: 0;
		right: 0;
	}

	.col-text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		padding: 1rem 3.5rem;

		.text-main {
			font-size: 62px;
		}

		.text-secondary {
			font-size: 16px;
			letter-spacing: 0.005em;
		}

		.btn-google {
			width: 180px;
			height: 60px;
			margin-right: 1.5rem;
			border-radius: 5px;
			border: none;
			background: var(--cyan-light);
			color: $color_1;
			margin-bottom: 14px;
		}

		.btn-apple {
			width: 180px;
			height: 60px;
			margin-right: 1.5rem;
			border-radius: 5px;
			border: none;
		}
	}

	.col-logos {
		padding: 1rem 2rem;
		min-height: 124px;
	}

	.text-subtitle {
		font-size: 36px;
		letter-spacing: 0.005em;
		color: $color_2;
	}

	.text-parrafo {
		font-size: 15px;
		letter-spacing: 0.005em;
		color: $color_3;
		text-align: center;
		font-weight: normal;
	}

	.card-invest {
		min-height: 150px;

		.card-logo {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.logo-container {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 56px;
			width: 56px;
			border-radius: 8px;
			background: #D4F7E9;
		}

		.card-title {
			font-weight: bold;
			font-size: 22px;
			color: $color_2;
		}

		.card-text {
			font-weight: normal;
			font-size: 14px;
			color: $color_3;
		}
	}

	.card-step {
		.card-logo {
			display: flex;
			align-items: center;
		}

		.logo-container {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 45px;
			width: 45px;
			border-radius: 45px;
			background: #D4F7E9;

			p {
				font-weight: bold;
				font-size: 20px;
				color: $color_4;
				margin-bottom: 0;
			}
		}

		.card-text {
			font-weight: normal;
			font-size: 16px;
			color: $color_2;
			padding-top: 5px;
		}
	}

	.haciendas-item {
		border-bottom: 1px solid #92929D;
	}

	.ant-list-pagination {
		text-align: center;
	}

	.text-input {
		font-weight: bold;
		font-size: 1.25rem;
		color: $color_5;
		margin-left: 1rem;
	}

	.row-divider {
		border-bottom: 1px solid rgba($color: $color_3, $alpha: 0.3);

		.ant-col {
			display: flex;
			justify-content: space-between;
			align-items: center;

			&:first-child {
				padding-right: 4rem !important;
			}

			&:nth-child(2) {
				padding-left: 4rem !important;
			}
		}
	}


	.invest-info {
		.flex-left {
			margin-bottom: 1rem;
			padding-left: 1.5rem;

			.text-green {
				font-weight: bold;
				font-size: 25px;
				margin: 0 1rem;
			}

			.text-money {
				font-weight: 500;
				font-size: 25px;
				margin: 0 1rem;
			}

			.text-cyan-light {
				font-weight: 300;
				font-size: 25px;
				margin: 0 1rem;
			}

			.text-cyan {
				font-weight: bold;
				font-size: 25px;
				margin: 0 1rem;
			}
		}
	}

	.simulador {
		&-text {
			font-size: 24px;
		}

		&-big-text {
			font-size: 28px;
		}
	}

}




.landing .input-landing,
.landing .input-landing.ant-input-number,
.landing .input-landing.ant-input-number-focused {
	border: none;
	box-shadow: none !important;
	margin: 0 !important;
}

.landing .input-landing,
.landing .input-landing.ant-input-number-group-wrapper,
.landing .ant-select.select-landing {
	border: 2px solid #2DDA93;
	border-radius: 9px;
	overflow: hidden;
	margin-top: 8px;
	width: 300px;
}


.landing .input-landing .ant-input-number-input,
.landing .select-landing .ant-select-selector,
.landing .select-landing .ant-select-selection-search-input {
	height: 46px !important;
}

.landing .input-landing .ant-input-number-affix-wrapper {
	border: unset !important;
}

.landing .select-landing .ant-select-selection-item,
.landing .select-landing .ant-select-selection-placeholder {
	line-height: 46px !important;
}

.landing .input-landing .ant-input-number-group-addon {
	background: white;
	border: none;
}

.landing .select-landing .ant-select-selector {
	border: none;
}




.landing .main .cell-img {
	// width: 100%;
	max-width: 400px;
}



.cell-logo {
	width: 40%;
	display: block;
	margin: 12px auto 0 auto;
	margin-top: 30%;

}

.card-absolute {
	width: 120px !important;
	height: 120px !important;
	background-color: $background-color_1;
	border-radius: 16px;
	box-shadow: 24px 28px 60px rgba(0, 0, 0, 0.25);

	&.dollar {
		position: absolute;
		top: 15%;
		left: -40px;
		transform: rotate(-17deg);
	}

	&.agave {
		position: absolute;
		bottom: 25%;
		right: -60px;
		transform: rotate(17deg);
	}
}

@media(max-width: 768px) {

	.landing {



		.row-divider {
			max-width: 83%;

			.ant-col {
				&:first-child {
					padding: 0 !important;
				}

				&:nth-child(2) {
					padding: 0 !important;
				}
			}
		}

		.layout {
			&-img-text {
				.layout {
					&-text .ant-typography {
						font-size: 18px;
						text-align: center;
					}
				}
			}
		}

		 .card-invest{
			height: 200px;
		 }
	}


	.footer-description {
		display: block !important;

		iframe {
			width: 100%;
			margin-top: 1em;
		}
	}

	.layout-img {
		background-size: cover !important;
		background-position: center !important;
		height: 40% !important;
	}
}

@media(max-width: 992px) {
	.landing {
		.carousel-bg {
			max-height: 64vh;
			width: 100vw;
			object-fit: contain;

			img {
				width: 100vw;
				height: 64vh;
				object-fit: cover;
			}
		}

		.main {
			max-height: unset;
			padding-bottom: 1rem;
			top: 0rem;
			height: 60% !important;

			.col-text {
				padding: 1rem;
			}
		}

		#about-us {
			margin-top: 35rem !important;
		}
	}

	.phone-content {
		width: 400px;
	}
}

@media (max-width:991px) {


	.landing {
		.carousel-bg {
			max-height: 168.5vh;
			object-fit: contain;

			img {
				width: 100%;
				height: 168.5vh;
				object-fit: cover;
			}
		}
	}

}

@media (max-width:822px) {


	.landing {
		.carousel-bg {
			max-height: 178vh;
			object-fit: contain;

			img {
				width: 100%;
				height: 178vh;
				object-fit: cover;
			}
		}
	}

}

@media (max-width: 797px) {
	.landing {
		.carousel-bg {
			max-height: 178vh;
			height: auto;
			object-fit: contain;

			img {
				width: 100%;
				height: 100%;
				max-height: 178vh;
				object-fit: cover;
			}
		}
	}

	.phone-content {
		width: 300px;

		.phone-contact {
			font-size: 14px;
		}
	}

	.card-absolute {
		width: 80px !important;
		height: 80px !important;

		&.dollar {
			top: 15%;
			left: -30px;

		}

		&.agave {
			bottom: 25%;
			right: -40px;
		}


	}

	.cell-logo {
		width: 50px !important;
	}

	#about-us {
		margin-top: 750px !important;
		overflow: hidden;
	}

	.layout-text {
		display: flex;
		align-self: center;
		align-content: center;
	}
}


@media (max-width: 669px) {
	#about-us {
		margin-top: 900px !important;
	}
}

@media (max-width: 527px) {
	#about-us {
		margin-top: 1000px !important;
	}
}

@media (max-width:425px) {

	.landing {
		.carousel-bg {
			min-height: 100vh !important;
			max-height: 100vh !important;
			object-fit: cover !important;

			img {
				width: 100%!important;
				height: 100vh!important;
				max-height: 100vh !important;
				object-fit: cover!important;
			}
		}

		.text-main {
			margin-top: 100px !important;
			font-size: 30px !important;
		}

		.small {
			font-size: 12px !important;
		}

		.simulador-text {
			font-size: 14px !important;
		}

		.simulador-big-text {
			font-size: 16px !important;
		}
		.mt-150{
			padding-top: 50px !important;
		}
		#about-us {
			margin-top: 0px !important;
		}
	}

	.phone-content {
		width: 200px !important;

		.ant-typography {
			font-size: 12px !important;
			margin-top: 2px !important;
		}

		.phone-contact {
			margin: 0 !important;
			padding: 0 !important;
			font-size: 10px;
			height: 10px !important;
		}

		.redirect-buttons.ant-col {
			height: 10px !important;
		}
	}
	

}


@media(max-width:880px) {
	.landing {
		.text-input {
			display: contents !important;
		}
	}
}

@media(max-width:1112px) {
	.landing {
		.text-input {
			font-weight: bold;
			font-size: 1.25rem;
			color: $color_4;
			margin-left: 1rem;
		}
	}
}

@media(max-width: 1200px) {
	.landing {
		.col-logos {
			padding-top: 4rem;
		}
	}

	.mt-50 {
		padding-top: 120px;
	}


}

@media(max-width: 1230px) {
	.landing {
		.main {
			.cell-img {
				width: 100%;
				max-width: 800px;
			}
		}

	}

}

@media (max-width:1400px) {
	.landing {
		.section {
			max-width: calc(100vw - 60px);
			display: block;
			margin: auto;
		}
	}
}

@media(max-width: 630px) {


	.landing {
		.invest-info {
			* {
				font-size: 14px !important;
			}

			svg {
				height: 20px !important;
				width: 20px !important;
			}
		}

		.simulador {
			&-text {
				font-size: 22px;
			}

			&-big-text {
				font-size: 22px;
			}
		}
	}

}


.footer-public {
	background: #17171F !important;
	border-radius: 0;
}