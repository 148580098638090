.support-activated {
    min-height: unset !important;
    display: flex !important;
    overflow: hidden;

    .sider-admin {
        overflow-y: auto;
    }

    .content-container {
        min-height: unset !important;
        display: flex;
        flex-direction: column;
        
    }

    .admin-content {
        height: 100%;
        // padding: 0  !important;
        // flex-grow: 100%;
        // flex-basis: auto;
    }

    .row-content{
        .row-list-cards{
            // background-color: red;
            overflow: auto;
            // padding-left: 8px !important;\
            padding-right: 16px !important;
            
        }
    }

}

.link-button {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    
    /*input has OS specific font-family*/
   
    cursor: pointer;
  }



#wrapper {
    width: 100%;
}

$msger-bg: #fff; // Replace with actual value
$border: 1px solid #ccc; // Replace with actual value
$left-msg-bg: #f1f1f1; // Replace with actual value
$right-msg-bg: #d1e7dd; // Replace with actual value

.conversation {
    height: 100%;
    min-height: 100vh;

    .ant-card-body {
        padding: 0 !important;
        border-radius: 10px !important;
        height: 100%;
    }

    .titulo{
        text-align: center;
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }
}



.chat-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;
    margin-top: 0.5em;
    border-radius: 10px;
}
        
.message-box {
    height: calc(100% - 150px);
    overflow-y: scroll;
    padding: 0 1rem 1rem 1rem;
    max-height: calc(100vh - 150px)
}

.messages-list-admin{
    border: solid 1px #ddd;
    height: 480px;
    overflow: hidden;
    overflow-y: auto;
    padding: 12px
}

.client-list{
    max-height: 600px;
    overflow: hidden;
    overflow-y: auto;
    padding: 12px;

    .no-leido{
        background-color: #d9d9d9;

        *{
            //color: white;
            font-weight: bold;
        }
    }
}

.bottom-content {
    min-height: 100px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    vertical-align: middle;

    .form-comment {
        width: 100%;
    }

    .mentions-form-item {
        width: 100%;
    }


}
