.hm-menu svg{
	width: 25px;
	min-height: 20px;
}

.hm-menu .ant-menu-title-content{
	margin-left: 10px;
}

.hm-menu .ant-menu-item-selected{
	background: #BAE8ED !important;
}

