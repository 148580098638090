.btn-primary {
    border: none !important;
    background: #16B1C2 !important;
}

.btn-primary-disabled {
    background: #A5CED2 !important;
}

.btn-secondary {
    border: none !important;
    background: #65CCCC !important;
}

.btn-delete {
    border: none;
    background: #FF547D;
}
.btn-warning {
    border: none!important;
    background: #ffd500!important;
}

.btn-borderless{
    border: none!important;
    box-shadow: none!important;
}
.btn-add-client {
    background: orange !important;
    opacity: 0.7;
    color: white !important;
    border: none !important;
}

.divider-cyan {
    background: var(--cyan-semi-dark);

}

.divider-medium {
    height: 1px;
}

.admin-tag {
    border-radius: 30px !important;
    opacity: 0.7 !important;
    border:  none !important
}

.admin-tag.tipo-1 {
    background: rgba(107, 226, 190, 0.34) !important;
}

.admin-tag.tipo-2 {
    background: rgba(255, 181, 114, 0.2) !important;
}

.admin-tag.estatus-1 {
    background: rgba(255, 181, 114, 0.2) !important;
}

.admin-tag.estatus-2 {
    background: rgba(107, 226, 190, 0.34) !important;
}

.admin-tag-green {
    background: rgba(107, 226, 190, 0.34) !important;
    color: #50D1AA !important;
}

.admin-tag-purple {
    background: rgba(146, 144, 254, 0.2) !important;
    color: #9290FE;
}

.admin-tag-orange {
    background: rgba(255, 181, 114, 0.2) !important;
    color: #FFB572;
}

.tag-cyan-light{
    background: var(--cyan-light) !important;
    color: #FFFFFF!important;
    border: none!important;
    width: 60px!important;
    text-align: center!important;
}
.tag-semi-cyan {
    background: var(--cyan-semi-dark) !important;
    color: #FFFFFF!important;
    border: none!important;
    width: 60px!important;
    text-align: center!important;
}

.w-100{
    width: 100% ;
}

.ant-btn:not(.ant-btn-default) .anticon {
    color: white;
}

.admin-page-header .ant-page-header-heading-title span:not(.ant-tag){
    font-size: 20px !important;
    color: white !important;
}