

.footer-public{
	background: #17171F;
	border-radius: 16px 16px 0 0;
	padding: 50px 100px;
}

.footer-public li{
	list-style: none;
	color: white;
	margin-left: 0;
}

.footer-public ul{
	padding-left: 0;
	
}

.mapouter{position:relative;text-align:left;height:200px;}
.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:200px;}
.gmap_iframe {height:200px!important;}