.button-floating {
    border: none !important;
    width: 80px !important;
    height: 80px !important;
    
    box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
    border-radius: 50px !important;
    bottom: 30px;
    right: 30px;
    z-index: 10;
    float: right!important;
    position: fixed !important;
    
}

.button-floating svg, .floating-stack-button svg {
    width: 41px !important;
    height: 41px !important;
}

.floating-stack{
    display: flex;
    justify-content: right;
    align-items: center;
    column-gap: 1rem;

    position: fixed;
    float: right;
    z-index: 10;
    bottom: 30px;
    right: 30px;
}

.floating-stack-button{
    border: none !important;
    width: 80px !important;
    height: 80px !important;
    
    box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
    border-radius: 50px !important;
}