.card-zeus {
    // ant-menu-overflow-item
    // ant-menu-item
    // ant-menu-item-active
    // ant-menu-item-selected
    // ant-menu-item-only-child
    .ant-menu-item-selected,
    .ant-menu-item-active
    {

        color: white !important; 
        

        &::after,
        &:hover,
        &:focus
        {
            color: white !important;
            border-color: white !important; 
        }
        // {
        //     border-color: white !important; 
        // }
    }

    .menu-options{
        position: absolute;
        top: 1em;
        left: 50%;
        z-index: 1;
        border-radius: 5px;
        transform: translateX(-50%);
        background: rgba(0,0,0,0.5);
        padding: 8px 6px;
        color: white;       
    }
}


.gallery {
    // display: flex;
    // flex-direction: column;
    // row-gap: 12px;

    height: 100%;

    .ant-carousel {

        height: 100%;
    }

    &-slide {
        // height: 320px;

        .ant-image {
            width: 100%;
            // height: 320px;
        }
    }

    &-carousel {
        position: relative;
        // width: 100%;
    }

    &-image {
        height: 100%;
        border-radius: 10px 0px 0px 10px;
        // object-fit: cover;
        // width: 100%;
        // height: 100%;
        // opacity: 0.5;
        // transform: scaleX(1) scaleY(1);
        // transition: all 0.5s;

        // &:hover{
        //     opacity: 1;
        //     transition: all 0.5s;
        // }
    }

    // &-prev{
    //     position: absolute;
    //     top: 50%;
    //     left: 5px;
    //     transform: translateY(-50%);
    // }
    // &-next{
    //     position: absolute;
    //     top: 50%;
    //     right: 5px;
    //     transform: translateY(-50%);
    // }

    // &-control{
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     height: auto;
    //     background-color: #2DDA93;
    //     border-radius: 100px;
    //     border: none;
    //     padding: 5px;
    //     cursor: pointer;

    // }




    // &-image{
    //     object-fit: cover;
    //     height: 320px !important;
    // }

    // &-dots{
    //     & li button{
    //         height: 10px !important;
    //         border-radius: 3px !important;
    //         opacity: 0.6 !important;

    //         &:hover{
    //             opacity: 0.9 !important;
    //         }
    //     }

    //     & li.slick-active button{
    //         opacity: 1 !important;
    //     }
    // }

    // .slide-buttons{
    //     & .slick-slide{
    //         padding: 0 6px;
    //     }
    //     & .slick-slide.slick-active.slick-current .slide-button{
    //         box-sizing: border-box;
    //         transition: all 0.5s;

    //         &-image{
    //             opacity: 1;
    //             transform: scaleX(0.9) scaleY(0.85);
    //         }
    //     }
    // }

    // .slide-button{
    //     height: 120px;
    //     background-color: #2DDA93;
    //     transition: all 0.5s;
    //     cursor: pointer;


    // }
}