
.card-empleado {
	margin-bottom: 1rem !important;
}

.card-empleado .label-title{
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 1px;
	color: #2A2A2A;
}

.card-empleado .label-text{
	font-weight: 700;
	font-size: 16px;
	line-height: 17px;
	letter-spacing: 1px;
	color: #000000;
	margin-bottom: 2rem;
	margin-top: 8px;
}

.detalles-section-title{
	background: white !important;
	padding: 0.5rem 1rem;
	font-weight: 400 !important;
	font-size: 14px !important;
	text-align: center !important;
	letter-spacing: 1px !important;
	max-width: 200px;
	color: #000000;
}