.nomina-check {
    color: #23C496!important;
    font-size: 20px;
    display: block;
    float: right;
}

.nomina-nocheck{
    color: red!important;
    font-size: 20px;
    display: block;
    float: right;
}

.nomina-calendar{
    color: #F50!important;
    font-size: 20px;
    display: block;
    float: right;
}

.dias_trabajados{
    font-size: 12px;
}