/*
* 
* CSS para los heders del sistema publicos y privados
*
*/
.header-drawer{
	.ant-drawer-content-wrapper{
		max-width: 378px !important;
		width: 80% !important;
	}
	.ant-drawer-body{
		padding: 16px 0 0 0;
		background-color: #001529;
	}
}

.header-menu-btn{
	background-color: transparent !important;
	color:rgba(51, 228, 155, 0.7) !important;
}

.header-public{
	display: flex;
	width: 100%;
	align-items: center;
	padding: 16px 50px !important;
	position: fixed;
	top: -1;
	left: 0;
	z-index: 100;
	background: linear-gradient(to bottom, #1b0040, #180133, #150127, #0f011a, #04000a);

	&-space{
		width: 100%;
		height: 100px;
	}

	& > .ant-row{
		height: 100%;
	}

	&-logo{
		width: auto;
		height: 60px;
		vertical-align: middle;
	}

	&-logged-btns{
		display: flex;
	}

	&-logged-btn{
		&.btn-green{
			width: auto !important;
		}

		&:nth-child(1).logged-btn{
			border-radius: 30px 0 0 30px !important;
			padding: 8px 12px 8px 24px !important; 
		}

		&:nth-child(2).logged-btn{
			border-radius: 0 30px 30px 0 !important;
			padding: 8px 24px 8px 12px !important; 
		}
	}

}
.menu-item-mobile, .header-public-menu-mobile{
	display: none !important;
	visibility: hidden !important;
}

@media (min-width: 900px) {
	.header-public{
		&-normal{
			height: 100px !important;
			// background: linear-gradient(to bottom, #1b0040, #180133, #150127, #0f011a, #04000a) !important;
			backdrop-filter: blur(15px) !important;
			background: #001529 !important;
			transition: all 0.5s;
		}
	
		&-compact{
			height: 70px !important;
			padding: 0px 50px !important;
			background: linear-gradient(to bottom, #04000a, #04000a) !important;
			transition: all 0.2s;
		}
	}
}

@media (max-width: 899px) {
	.header-public{
		min-height: 100px !important;
	}
}

.header-public .header-menu{
	background: transparent !important;
	width: 100%;
}

.header-public .header-menu .ant-menu-item{
	color: #fff;
	font-weight: 300;
}

.header-public .header-menu .ant-menu-item:hover{
	background: transparent;
	color: var(--cyan);
}

.header-public .header-menu .ant-menu-item-selected{
	background: transparent;
	color: var(--cyan-light);
	font-weight: bold;
}

.btn-green{
	background: linear-gradient(118.77deg, #2DDA93 0%, #4BD79E 111.54%)!important;
  	box-shadow: 4px 4px 20px rgba(51, 228, 155, 0.7)!important;
  	border-radius: 30px!important;
  	height: 40px!important;
  	color: white!important;
  	font-size: 16px!important;
  	border: none!important;

	&:not(.w-100){
		width: 150px!important;
	}

	&.disabled {
		opacity: 0.4;
	}
}

.header-public .ant-dropdown-trigger{
	color: white;
	margin-left: 1rem;
}

.header-public .right{
	margin: 0 0 0 auto;
}
.header-public .ant-menu.ant-menu-dark .ant-menu-item-selected,
.header-public .ant-menu.ant-menu-dark .ant-menu-item:hover{
	background-color: transparent;
}

@media(max-width:  1310px){
	.header-public .text-cyan-light,
	.header-public .text-white{
		display: none;
	}
}

@media(max-width:  600px){

	
	.header-public .ant-menu-overflow{
		margin-left: 0px !important;
	}
}



@media(max-width:  881px){

	.header-public{
		padding: 0.5rem !important;	
		display: flex !important;
		flex-direction: column;
	}

	.header-menu{
		width: auto !important;
		display: inline-block;
	}

	.div-container{
		width: 100%;
		flex-direction: row;
		display: flex;
		align-content: center;
		justify-content: center;
		line-height: 64px;
	}
	
	.space-menu{
		display: flex !important;
		flex-direction: column;
		align-items: flex-start !important;
		justify-content: flex-start;
	}

	.menu-item-mobile, .header-public-menu-mobile{
		display: block !important; 
		visibility: visible !important;
	}

	.menu-item-desktop, .header-public-desktop, .header-public-menu-desktop{
		display: none !important; 
		visibility: hidden !important;
	}

}

@media(min-width:  881px){
	.menu-item-desktop, .header-public-desktop, .header-public-menu-desktop{
		display: block !important; 
		visibility: visible !important;
	}
	.div-container{
		width: 100%;
		flex-direction: row;
		display: flex;
		align-content: center;
		justify-content: center;
		line-height: 64px;
	}
	
}

@media(max-width:  920px){

	.btn-green.menu{
		display: none !important;
	}

	.btn-green.out{
		margin-top: 10px;
	}

}

@media(min-width:  920px){
	.btn-green.out{
		display: none !important;
	}

	
}
@media(max-width:  591px){
	/* .ant-layout-header.header-public{
		padding: 10px;

	}
	.header-public .header-menu{
		max-width: inherit!important;
	} */
	.header-public img{
		/*display: none;*/
	}
}
@media(max-width:  918px){
	.header-public .ant-dropdown-trigger{
		display: none;
	}
}

@media(max-width:  590px){
	.header-public{
		flex-direction: column;
		height: unset;
	}
}

.header-admin{
	padding: 0 !important;
	height: unset !important;
	background: white !important;
}

.header-admin .logo-header{
	width: 100%;
	max-width: 250px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	background: rgba(57, 203, 174, 0.189);
}

.header-admin .header-content{
	padding-top: 0.6rem;
}
.ant-layout-header{
	min-height: 100px !important;
}
.header-admin .ant-input-group-wrapper{
	max-width: 500px;
	margin-left: 1rem;
}

.notifications-list{
	width: 280px;
}
.notification-element{
	height: 70px!important;
}
.notification-element .ant-menu-title-content .ant-comment .ant-comment-inner
.ant-comment-content
.ant-comment-content-detail ,
.notification-element .ant-menu-title-content .ant-comment .ant-comment-inner
.ant-comment-content .ant-comment-actions{
	margin: 0px;
	padding: 0px;
	line-height: 15px;
}

@media(max-width:835px){
	/* .header-admin .width-100{
		padding-top: 10px;
	} */
	.header-admin .logo-header{
		display: none;
	}
}