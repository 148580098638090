.card-hacienda{
	width: 220px;
	height: 300px;
	/*background: linear-gradient(204.67deg, #F0C735 9.43%, #D98F39 84.49%);*/
	box-shadow: 10px 10px 10px rgba(97, 97, 97, 0.46);
	border-radius: 20px;
	border: none !important;
	margin-bottom: 1rem !important;
	overflow: hidden;
}

.card-hacienda .ant-card-head-title,
.card-hacienda .ant-typography, .card-hacienda-title{
	color: white !important;
}

.card-hacienda .ant-card-head-title{
	white-space: normal !important;
} 

.card-hacienda-title{
	padding-top: 0.5rem !important;
	padding-bottom: 0 !important;
	font-weight: bold !important;
	font-size: 25px !important;
	word-break: break-all !important;
	letter-spacing: 0.25px !important;
}

.card-hacienda .ant-typography{
	font-family: Poppins;
	font-style: normal;
	font-weight: 200;
	font-size: 15px;
}

.card-hacienda .ant-card-body{
	padding-top: 8px !important;
	height: 240px;
	padding-bottom: 0 !important;
	position: relative;
}

.card-hacienda .ant-card-body .agabe-svg{
	height:180px;
	width: 200px;
	position: absolute;
	bottom: -10px;
	right: -55px;
}


/*-------------------------------CardStatistics----------------------------------------*/

.card-statistics .ant-card-body{
	padding-top: 6px !important;
}

.card-statistics .card-title{
	font-size: 24px;
	color: #A0AEC0;
	margin-bottom: 0px !important;
}

.card-statistics .card-content{
	margin: 24px 0px -5px 0px;
}

.card-statistics .card-monto{
	font-weight: bold;
	font-size: 24px;
}

.card-statistics .card-suffix{
	font-weight: bold;
	font-size: 19px;
	text-transform: uppercase;
}

.card-statistics .icon-container{
	border-radius: 40px;
	width: 58px;
	height: 58px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.card-statistics .icon-container > * {
	font-size: 28px;
	width: 28px;
	height: 28px;
}

.card-statistics .card-percent .card-monto{
	font-size: 14px;
	font-weight: normal;
}

.card-statistics .card-percent.green *{
	color: #00CD98;
}

.card-statistics .card-percent.red *{
	color: #FF4D4F;
}

.card-statistics .card-icon{
	display: flex;
	align-content: center;
}

.card-statistics .card-icon > *{
	font-size: 16px;
	width: 16px;
	height: 16px;
}

.card-statistics-small .card-title{
	font-weight: 500;
	font-size: 14px;
	color: #ABBBC2;
}

.card-statistics-small .card-monto{
	font-weight: 600;
	font-size: 28px;
	color: #16B1C2;
	margin-bottom: 8px;
}

.card-statistics-small .icon-container{
	background: #16B1C2;
	box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
	border-radius: 12px;
	width: 38px;
	height: 38px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 8px;
}


/*-------------------------------CardProducto para POS---------------------------------------*/

.card-product{
	height: 290px;
	background: #16B1C2 !important;
	border:  none !important;
	border-radius: 8px;
	margin: 0 10px !important;
}

.card-product.hover:hover{
	background: #99D8DF !important;
}

.card-product .product-container{
	position: relative;
	height: 120px;
}

.card-product .product-image{
	position: absolute;
	top: 20%;
  	left: 50%;
	border-radius: 50%;
	border:1px solid #DADADA;
	
  	transform: translate(-50%, -60%);
}

.card-product .ant-typography{
	margin-bottom: 0.8rem;
	color: white;
	text-align: center;
}

.card-product .product-description{
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;

	color: #FFFDFD;
}

/*-------------------------------CardProductoVenta para POS---------------------------------------*/

.card-producto-venta{
	margin: 0 0 1.5rem 0;
}

.card-producto-venta .producto-descripcion{
	font-size: 12px;
	color: #000000;
	text-overflow: ellipsis;
	max-height: 22px;
	overflow: hidden;
	white-space: nowrap;
	max-width: 95%;
}

.card-producto-venta .producto-price{
	font-size: 11px;
	color: #ABBBC2;
}

.card-producto-venta .ant-input-number{
	border: none;
	background-color: rgba(176, 222, 228, 0.3);
}

.card-producto-venta  .ant-input-number-handler-wrap{
	background-color: rgba(176, 222, 228, 0.3);;
}

.card-producto-venta .ant-input{
	background-color: rgba(176, 222, 228, 0.3);
	border: none;
	border-radius: 10px;

}

.card-producto-venta .producto-delete{
	width: default;
}

.card-producto-venta .ant-btn{
	border-color: #16B1C2 !important
}

@media screen  and (max-width: 1024px) {
	.card-statistics-small .card-monto{
		font-size: 18px!important;
	}
	.card-statistics-small .card-title{
		font-size: 10px!important;
	}
	
}

@media screen and (max-width:1400px){
	.card-producto-venta .producto-delete{
		width: 100%!important;
	}
}

.card-empleado-finanzas .ant-form-item {
	width: 100% !important;
	margin-bottom: 0px !important;
}

.card-empleado-finanzas .button-pagar {
	background-color: #6100FF !important;
	border-color: #6100FF !important;
}

.card-empleado-finanzas .button-pagado {
	background-color: #2DDA93 !important;
	border-color: #2DDA93 !important;
	cursor: not-allowed;
}

.card-half-screen{
	min-height: calc((100vh - 100px - 64px - 16px) / 2);
}