.layout-dashboard .ant-page-header-heading-title {
  color: var(--cyan);
}

.layout-dashboard .ant-page-header-heading-extra {
  color: var(--cyan-dark);
  font-size: 20px;
  font-weight: 700;
}


.card-green {
  background: linear-gradient(273.13deg, #33DA96 -2.73%, #3AC18A 118.57%) !important;
  min-height: 130px;
}

.card-green .ant-btn {
  border-radius: 10px;
  font-size: 12px;
  color: var(--green-dark);
  margin: 0 0 0 auto;
}

.card-purple {
  background: linear-gradient(275.33deg, #6833DA 36.96%, #9247C0 89.91%) !important;
  min-height: 130px;
}

.card-cyan {
  background: linear-gradient(273.13deg, #65CCCC -2.73%, #16B1C2 118.57%) !important;
  min-height: 130px;
}

.card-green .ant-typography,
.card-purple .ant-typography,
.card-cyan .ant-typography {
  font-size: 20px;
  font-weight: bold;
}

.card-green .ant-typography small,
.card-purple .ant-typography small,
.card-cyan .ant-typography small{
  font-size: 14px;
  font-weight: bold;
}

.card-green .ant-card-head-title,
.card-purple .ant-card-head-title,
.card-cyan .ant-card-head-title
 {
  color: white;
  font-size: 25px;
  font-weight: 500;
}

.card-green .ant-typography,
.card-purple .ant-typography,
.card-cyan .ant-typography{
  color: white;
  font-size: 25px;
  font-weight: 700;
}
.card-green .ant-card-body,
.card-purple .ant-card-body,
.card-cyan .ant-card-body {
  height: 65%;
  display: flex;
  align-items: center;
}
/* 
.card-hacienda-compra{
  min-height: 400px;
} */

.card-hacienda-compra svg {
  margin: 0 5px;
}

.card-hacienda-compra .row-divider {
  border-top: solid 1px white;
  border-bottom: solid 1px white;
  padding: 18px 0;
  margin: 4px 0;
}

.card-hacienda-compra .row-data {
  padding: 18px 0;
  font-size: 20px;
}

.card-hacienda-compra .btn-green {
  background: linear-gradient(118.77deg, #2DDA93 0%, #4BD79E 111.54%);
  box-shadow: 4px 4px 20px rgba(51, 228, 155, 0.7);
  border-radius: 30px;
  float: right;
  width: 200px;
  height: 50px;
  color: white;
  font-size: 16px;
}