.cliente-detalles{
    &-reventas{
        .reventas{
            &-select{
                .ant-select-selector{
                    background-color: white !important;
                }
            }
        }
    }
}