/*
*
* 	CSS PARA LOS MODALES EN GENERAL
*
*/


.ant-modal-mask{
	background-color: rgba(0, 0, 0, 0.06) !important;
	backdrop-filter: blur(0.3em);

}

.modal-border  .ant-modal-header,
.modal-border .ant-modal-content {
	
	border-radius: 20px;
}
.modal-zeus {
	border-radius: 20px;
	overflow: hidden;
	padding-bottom: 0 !important;

}

.modal-zeus .ant-modal-content {
    background-color: rgba(255,255,255,0.7);
    backdrop-filter: blur(15px);
	transition: all 1s ease-out;
}

.modal-zeus .ant-modal-body{
	padding: 24px 30px;
}

.modal-zeus .card-hacienda-modal{
	background: var(--cyan);
	border-radius: 15px;
	margin: 1.5rem 0;
	border:  none;
}

.modal-zeus .card-hacienda-modal h1{
	margin-bottom: 8px;
	font-size: 28px;
}

.modal-zeus .card-hacienda-modal *{
	color: white;
}

.modal-zeus .text-input{
	font-weight: bold;
	font-size: 18px;
	color: var(--cyan);
	margin-left: 0.5rem;
}

.modal-zeus .text-result{
	font-weight: bold;
	font-size: 18px;
	margin-left: 0.5rem;
}

.modal-zeus .input-landing.ant-input-number,
.modal-zeus .input-landing.ant-input-number-focused{
	border: none;
}

.modal-zeus .input-landing.ant-input-number-group-wrapper,
.modal-zeus .input-landing.ant-input,
.modal-zeus .ant-select.select-landing {
	border: 2px solid var(--cyan);
	border-radius: 9px;
	overflow: hidden;
	margin-top: 8px;
	width: 100%;
}

.modal-zeus .input-landing .ant-input-number-input,
.modal-zeus .input-landing.ant-input,
.modal-zeus .select-landing .ant-select-selector{
	height: 46px;
}

.modal-zeus .select-landing .ant-select-selection-item,
.modal-zeus .select-landing .ant-select-selection-placeholder{
	line-height: 46px;
}

.modal-zeus .input-landing .ant-input-number-group-addon{
	background:white;
	border: none;
}

.modal-zeus .select-landing .ant-select-selector{
	border:  none;
}

.modal-zeus .btn-submit{
	background: linear-gradient(118.77deg, #65CCCC 0%, #16B1C2 111.54%);
	box-shadow: 4px 4px 20px #65CCCC;
	border-radius: 30px;
	border:  none;
	height: 40px;
	color:  white;
	font-weight: bold;
}

.modal-zeus .btn-submit.large{
	width: 100%;
}

.modal-zeus .btn-step,
.modal-zeus .btn-step:hover,
.modal-zeus .btn-step:focus{
	background: var(--cyan-light);
	color: white;
	border-radius: 8px;
	min-width: 150px;
}
.modal-zeus .btn-step-back{
	color: var(--cyan-light);
	border: 1px solid var(--cyan-light);
	border-radius: 8px;
	min-width: 150px;
}

.modal-zeus .modal-subtitle,
.modal-zeus .modal-title{
	color: #2F327D;
	text-align: center;
}

.modal-zeus::after {
    content: '';
    color: black;
    font-weight: bold;
    position: absolute;
    top:  0;
    bottom: 24px;
    left: 0;
    right: 0;
    border-radius: 20px;
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
    filter: blur(5px);
    z-index: -1;
}

/*Solo para FireFox*/
@supports (-moz-appearance:none) {
   .modal-zeus::after { 
        background: rgb(200,200,200,0.8);  
    }

    .modal-zeus .ant-modal-body{
        background: rgba(255, 255, 255, 0.3) !important; 
    }
}


.radio-payment-selection{
    width: 100%;
    justify-content: center;
    text-align: center;
    display: flex !important;
}

.radio-payment-selection .button-payment-selection{
    width: 50%;
    text-align: center;
    line-height: 18px;
    height: 49px;
    margin-bottom: 2rem;
}


.radio-payment-selection .button-payment-selection .icon{
    margin-top: 4px;
    display: inline-block;
}

.radio-payment-selection .button-payment-selection svg{
   height: 40px;
   width: 40px;
}

.radio-payment-selection .button-payment-selection .credit-card{
    margin-right: 8px;
}

.radio-payment-selection .button-payment-selection .text-payment-selection{
    display: inline-block;
    position: relative;
    bottom: 12px;
    font-weight: bold;
    font-size: 14px;
}