.soporte {
    position: fixed;
    height: 600px;
    max-height: 75vh;
    max-width: 85vw;
    width: 450px;
    bottom: 45px;
    left: 25px;
    overflow: hidden;
    background-color: white;
    border-radius: 8px;
    
    transition: all 0.5s ease-in-out;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;

    &.oculto {
        display: none;
        
    }


    
}

.titulo {
        background-color: #33DA96 !important;
        padding: 0.8em;
        text-align: center;
        color: white;
        font-weight: 700;
        font-size: 20px;
        text-transform: capitalize;
}

.chat-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;
    margin-top: 0.5em;
}
        
.message-box {
    height: calc(100% - 150px);
    
    max-height: 100%
}

.bottom-content {
    min-height: 100px;

    vertical-align: middle;

    .form-comment {
        width: 100%;
    }

    .mentions-form-item {
        width: 100%;
    }


}

.messages-list{
    height: calc(100% - 150px);
    overflow: hidden;
    overflow-y: auto;
    padding: 12px
}

.oculto {
    display: none;
}