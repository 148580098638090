html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.test-banner{
  opacity: 0.4;
  position: sticky;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: linear-gradient(118.77deg, #2DDA93 0%, #4BD79E 111.54%)!important;
  box-shadow: 0 -.3rem 1rem rgba(0,0,0,.15)!important;
  transition: all 0.2s ease;
  cursor: default;
}

.test-banner:hover{
  opacity: 1;
}

.test-banner .test-banner-text {
  max-width: 750px;
  display: block;
  margin:  0 auto;
  padding: 0.3em 0;
  color: white;
  font-size: 18px;
}


@media (max-width: 576px) { 
  .test-banner .test-banner-text {
    font-size: 12px;
  } 
}
@media (max-width: 768px) { 
  .test-banner .test-banner-text {
    font-size: 14px;
  } 
}
@media (max-width: 992px) {
  .test-banner .test-banner-text {
    font-size: 16px;
  }
}

iframe#webpack-dev-server-client-overlay{display:none!important}